import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styles from "./GameScreen.module.css";
import { endNavigate } from "../../redux/actions/navigation";
import IconGrid from "../../components/IconGrid/IconGrid";
import Menu from "../../components/Menu/Menu";
import { getLabel } from "../../constants/Strings";
import { Button, Modal, Icon } from "semantic-ui-react";
import { updateCredit, userCanWinNow,fetchUserInfo } from "../../redux/actions/user";
import Congratulation from "../../components/Congratulation/Congratulation";

const DEFAULT_PRICE = 5;
const DEFAULT_LOGO_PRICE = 100000;

class GameScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      iconsData: this.shuffleArray(this.getInitialIconsData()),
      showLowBalanceModal: false,
      shuffledNumbers: this.generateShuffledNumbers(),
      revealAllIcons: false,
      showCongratulation: false,
      isSelectionMade: false,
      showModalConditionPlay: false,
      amountWin: 0,
      canWinNow: false,
    };

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    
    dispatch(fetchUserInfo());
  }


 


  getInitialIconsData = () => {
    this.props.dispatch(userCanWinNow());
    return [
      { src: "/sadImage.png" },
      { src: "/logo3_1.png", isWinner: true, bonus: false },
      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 1000,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },

      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },

      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 2500,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },

      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 1000,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 1000,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 2500,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
    ];
  };

  shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  generateShuffledNumbers = () => {
    const numbers = Array.from({ length: 99 }, (_, i) =>
      String(i + 1).padStart(2, "0")
    );
    numbers.sort(() => Math.random() - 0.5);
    return numbers;
  };



  handleSelection = (icon, index, iconsArray) => {
 
   const { profile={} } = this.props?.user;
    let amountRemoveOnBonus = 0;
    let amountRemoveOnBalance = 0;

    if(profile?.bonus<DEFAULT_PRICE){
      amountRemoveOnBonus = profile?.bonus ?? 0;
      if(amountRemoveOnBonus>0){
        this.props.dispatch(updateCredit(amountRemoveOnBonus, "Bonus", "Reduce","ReduceBonus",false));
        amountRemoveOnBalance = DEFAULT_PRICE - profile?.bonus;
        this.props.dispatch(updateCredit(amountRemoveOnBalance, "Balance", "Reduce","ReduceBalance",false));
      }else{
      
        amountRemoveOnBalance = DEFAULT_PRICE;
        this.props.dispatch(updateCredit(amountRemoveOnBalance, "Balance", "Reduce","ReduceBalance",false));
       
      }
      
    }else{
    
      amountRemoveOnBonus = DEFAULT_PRICE;
      this.props.dispatch(updateCredit(amountRemoveOnBonus, "Bonus", "Reduce","ReduceBonus",false));
      
    }

  
   
    let updateAmount = 0;
    let limitAcceptWin = this.props.user?.canWin?.amount;

    if(limitAcceptWin<0){
      limitAcceptWin =0;
    }


    if(icon.isWinner === true){
      if(icon.bonus === true){
        updateAmount = icon.label;
      }else{
        updateAmount = DEFAULT_LOGO_PRICE;
      }
    }


    if (
      !this.props.user?.canWin?.allowedWin ||
      updateAmount > limitAcceptWin
    ) {

      const nonWinnerIndex = iconsArray.findIndex((item, idx) => !item.isWinner && idx !== index);
      if (nonWinnerIndex !== -1) {
        [iconsArray[index], iconsArray[nonWinnerIndex]] = [iconsArray[nonWinnerIndex], iconsArray[index]];
      }

      //debugger;
      this.setState({
        iconsData: [...iconsArray],
        revealAllIcons: true,
        isSelectionMade: true,
      });
      return;
    }



    if (icon?.isWinner) {
   
      const Balance_Bonus = icon.bonus ? "Bonus" : "Balance";


      if(Balance_Bonus ==="Balance"){
        this.props.dispatch(
          updateCredit(updateAmount, Balance_Bonus, "add","Logo",true)
        );
      }else{
        this.props.dispatch(
          updateCredit(updateAmount, Balance_Bonus, "add","AddBonus",true)
        );
      }
  
      

      this.props.dispatch(
        fetchUserInfo()
      );

      this.setState({ showCongratulation: true, amountWin: updateAmount });
    }

    this.setState({ revealAllIcons: true, isSelectionMade: true });
  };

  handleReplay = (e) => {
   // this.checkFundsAndNavigate(); // Deduct balance/bonus on replay

    const { profile={} } = this.props.user;
    
 
    if (
      (profile && profile?.balance >= DEFAULT_PRICE) ||
      profile?.bonus >= DEFAULT_PRICE
    ) {
      this.setState({ showModalConditionPlay: true });
      this.setState({
        showCongratulation: false,
        iconsData: this.shuffleArray(this.getInitialIconsData()),
        revealAllIcons: false,
        isSelectionMade: false,
        shuffledNumbers: this.generateShuffledNumbers(),
      });
      e.preventDefault();
    } else {
      this.setState({ showLowBalanceModal: true });
    }
  };

  handleCloseModal = () => this.setState({ showLowBalanceModal: false });

  handleDeclineModal = () => this.setState({ showModalConditionPlay: false });
  handleAcceptModal = () => {
    this.setState(
      { showModalConditionPlay: false, continuePlay: true },
      this.handlePlay
    );
  };

  render() {
  
    const { iconsData, revealAllIcons, showCongratulation, isSelectionMade, shuffledNumbers } = this.state;



    return (
      <div className={styles.container}>
  <Modal
          open={this.state.showLowBalanceModal}
          onClose={this.handleCloseModal}
        >
          <Modal.Header style={{ backgroundColor: "red", color: "white" }}>
            {getLabel("LowBalance")}
          </Modal.Header>
          <Modal.Content style={{ backgroundColor: "red", color: "white" }}>
            {getLabel("LowBalanceText")}
          </Modal.Content>
          <Modal.Actions style={{ backgroundColor: "red", color: "white" }}>
            <Button onClick={this.handleCloseModal}>{getLabel("Close")}</Button>
          </Modal.Actions>
        </Modal>

        {/* Condition to Play Modal with Warning */}
        <Modal
          open={this.state.showModalConditionPlay}
          onClose={this.handleDeclineModal}
        >
          <Modal.Content style={{ backgroundColor: "#fffbeb", color: "#856404" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon name="warning circle" color="yellow" />
              <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                {getLabel("RulesFeeText")} {DEFAULT_PRICE} Gourdes. 
              </span>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ backgroundColor: "#fffbeb", color: "#856404" }}>
            <div
              style={{
                justifyContent: "center",
                flex: 1,
                display: "flex",
                padding: "5%",
              }}
            >
              <Button
                onClick={this.handleDeclineModal}
                style={{ marginRight: "10px" }}
              >
                {getLabel("Decline")}
              </Button>
              <Button onClick={this.handleAcceptModal}>
                {getLabel("Accept")}
              </Button>
            </div>
          </Modal.Actions>
        </Modal>

        <Menu user={this.props.user}/>

        {showCongratulation && <Congratulation bonus={this.state.amountWin} />}
        <div className={styles.centerContent}>
          <IconGrid
            iconsArray={iconsData}
            onSelection={this.handleSelection}
            revealAllIcons={revealAllIcons}
            shuffledNumbers={shuffledNumbers}
          />
        </div>

        <div className={styles.positionPlayButton}>
          <div className={styles.playButton}>
            <Button
              style={{ backgroundColor: "#F99500", color: "white" }}
              onClick={this.handleReplay}
              disabled={!isSelectionMade}
            >
              {getLabel("Replay")}
            </Button>
          </div>

          <div className={styles.cancelButton}>
            <Button
              style={{ backgroundColor: "#F99500", color: "white" }}
              onClick={() => this.props.history.push("/home-screen")}
              disabled={!isSelectionMade}
            >
              {getLabel("Cancel")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ user, navigation }) {
  return { user, navigation };
}

export default connect(mapStateToProps)(GameScreen);

import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import { API_FAILURE, INSERT_NOTIFICATION, DELETE_NOTIFICATIONS,GET_NOTIFICATIONS } from "./types";
import { getCurrentLanguage } from "../../constants/Utils";

import Cookie from "react-cookies";
function requestSuccess(type, data) {
	return { type, payload: { data } };
}

function requestFail(errorCode, language) {
	return { type: API_FAILURE, payload: { data: errorCode, language } };
}

export function insertNotification(notification) {
	return dispatch => {
		dispatch(requestSuccess(INSERT_NOTIFICATION, notification));
	};
}

export function deleteNotification(_id) {
	return dispatch => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.delete("/deleteNotification ", {
			data: { _id }
		})
			.then(response => {
				if (response.data.success) {
					dispatch(requestSuccess(DELETE_NOTIFICATIONS, _id));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch(error => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export  function getUserNotification() {
	
	return async (dispatch) => {
		const userId = await Cookie.load("userId");
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.get(`/user/${userId}/notifications`)
			.then(response => {
				if (response.data.success) {
					dispatch(requestSuccess(GET_NOTIFICATIONS, response.data.result));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch(error => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

const toFormUrlEncoded = (obj) =>
	Object.keys(obj)
	  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
	  .join("&");


export function createNotification(notificationData) {
	//debugger;
	
	return async dispatch => {
	  const language = getCurrentLanguage();
	   const userId = await Cookie.load("userId");
	  dispatch(apiStart());
		
	  const formData = toFormUrlEncoded(notificationData);

	  try {
		const response = await Api.post(`/user/${userId}/notification`, formData);
		
		if (response.data.success) {
		  dispatch(requestSuccess(INSERT_NOTIFICATION, response.data.result));
		  dispatch(apiEnd());
		} else {
		  dispatch(apiEnd());
		  dispatch(requestFail(response.data.message, language));
		}
	  } catch (error) {
		dispatch(apiError(error, language));
		dispatch(apiEnd());
	  }
	};
  }
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Table,
  Pagination,
  Icon,
  Modal,
  Button,
  Loader,
} from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { currencyFormat, dateFormat } from "../../constants/Utils";
import { isEmpty } from "lodash";
import { isSmallDevice } from "../../constants/Layout";
import { endNavigate } from "../../redux/actions/navigation";
//import { getPendingTransactions, sendUserNotification } from "../../redux/actions/user";
import {
  getPendingTransactions,
  acceptPendingTransaction,
  rejectPendingTransaction
} from "../../redux/actions/user";
import Container from "../../components/Container/Container";
import styles from "./ValidationTransactionScreen.module.css";

const ValidationTransactionScreen = (props) => {
  const [allItems, setAllItems] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageLimit] = useState(15);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!props.user.transaction || props.user.transaction.length === 0) {
        await props.dispatch(getPendingTransactions()); // Fetch data if not loaded
      } else {
        setLoading(false);
      }
    };

    fetchTransactions();

    if (props.navigation.navigate) {
      props.dispatch(endNavigate());
    }

    window.scrollTo(0, 0);
  }, [props]);

  useEffect(() => {
    if (props.user.transaction) {
      setAllItems(props.user.transaction);
      setTotalPages(Math.ceil(props.user.transaction.length / pageLimit));
      setCurrentItems(props.user.transaction.slice(0, pageLimit));
      setLoading(false);
    }
  }, [props.user.transaction, pageLimit]);

  const onPageChanged = (e, data) => {
    e.preventDefault();
    const offset = (data.activePage - 1) * pageLimit;
    setCurrentItems(allItems.slice(offset, offset + pageLimit));
    setActivePage(data.activePage);
  };

  const handleRowClick = (transaction) => {
    setSelectedTransaction(transaction);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedTransaction(null);
  };

  const handleAction = async (action) => {

    if (selectedTransaction) {
     
      if(action === "accept"){
        await props.dispatch(acceptPendingTransaction(selectedTransaction.id));
      }else{
        await props.dispatch(rejectPendingTransaction(selectedTransaction.id));
      }
    
    }

    // Close the modal
    closeModal();
  };

  const renderTableRows = (data) => {
    if (!isEmpty(data)) {
      return data.map((item, index) => (
        <Table.Row
          key={index}
          onClick={() => handleRowClick(item)}
          style={{
            cursor: "pointer",
            backgroundColor: "rgba(255, 200, 0, 0.2)",
          }}
        >
          <Table.Cell className={styles.text}>{item.type}</Table.Cell>
          <Table.Cell className={styles.text}>Pending</Table.Cell>
          <Table.Cell className={styles.text}>
            {dateFormat(item.createdDate)}
          </Table.Cell>
          <Table.Cell className={styles.text}>
            {currencyFormat(item.amount)}
          </Table.Cell>
        </Table.Row>
      ));
    }
    return null;
  };

  if (loading) {
    return (
      <Loader active inline="centered" content="Loading transactions..." />
    );
  }

  return (
    <Container title={getLabel("Validation Transactions")}>
      <div className={styles.tableContainer}>
        <Table compact celled unstackable size={"small"} columns={16}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className={styles.titleText}>
                {getLabel("Type")}
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.titleText}>
                {getLabel("Status")}
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.titleText}>
                {getLabel("Date")}
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.titleText}>
                {getLabel("Amount")}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{renderTableRows(currentItems)}</Table.Body>
        </Table>
      </div>
      <div
        className={isSmallDevice ? styles.smallPagination : styles.pagination}
      >
        <Pagination
          activePage={activePage}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true,
          }}
          firstItem={null}
          lastItem={null}
          prevItem={{ content: <Icon name="angle left" />, icon: true }}
          nextItem={{ content: <Icon name="angle right" />, icon: true }}
          totalPages={totalPages}
          onPageChange={onPageChanged}
        />
      </div>

      <Modal open={modalOpen} onClose={closeModal} size="small">
        <Modal.Header>Transaction Details</Modal.Header>
        <Modal.Content>
          {selectedTransaction && (
            <div style={{ textAlign: "center", padding: "20px" }}>
              <p>
                <strong>User Balance:</strong>{" "}
                {currencyFormat(props.user.profile?.balance ?? 0)}
              </p>
              <p>
                <strong>Amount to Withdraw:</strong>{" "}
                {currencyFormat(selectedTransaction.amount)}
              </p>
              <p>
                <strong>Request Date:</strong>{" "}
                {dateFormat(selectedTransaction.createdDate)}
              </p>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            color="green"
            onClick={() => handleAction("accept")}
            style={{ width: "45%" }}
          >
            Accept
          </Button>
          <Button
            color="red"
            onClick={() => handleAction("reject")}
            style={{ width: "45%" }}
          >
            Reject
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

function mapStateToProps({ user, transactions, navigation }) {
  return { user, transactions, navigation };
}

export default connect(mapStateToProps)(ValidationTransactionScreen);

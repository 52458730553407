// import { createStore } from "redux";
// import reducer from "./reducers";
// import middleware from "./middleware";

// const store = createStore(reducer, middleware);

// export default store;


import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;

import React, { PureComponent } from "react";
import { Feed, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
//import { generateMessage } from "../../constants/Notifications";
import ReactTimeAgo from "react-time-ago/no-tooltip";

import styles from "./Notifications.module.css";

class Notifications extends PureComponent {
	render() {
		//const { onDelete } = this.props;
		//const { created, code, data, type, id } = this.props.data;
		//const { date, text, type, id } = this.props.data;
		const { date, text, type } = this.props.data;

		
		//const date = new Date(created);
		//const language = this.props.settings.language;
		//const text = generateMessage(code, language, data);
		switch (type) {

			case "Recharge":
				return (
					<div
					  className={styles.notificationContainer}
					  style={{ backgroundColor: "#d9ead3" }}
					>
					  <Feed size={"small"}>
						<Feed.Event>
						  <Feed.Content>
							<div className={styles.feed}>
							  <Feed.Extra text className={styles.feedText}>
								<Icon color={"green"} name={"money"} className={styles.feedIcon} />
								{text}
							  </Feed.Extra>
							  {/* Uncomment if needed */}
							  {/* <Icon
								color={"black"}
								name={"trash"}
								className={styles.deleteIcon}
								onClick={(e) => {
								  onDelete(e, id);
								}}
							  /> */}
							</div>
							<Feed.Date
							  className={styles.notificationTimeStyle}
							  content={
								<ReactTimeAgo
								  timeStyle="time"
								  date={new Date(date).getTime()} // Ensure date is a valid timestamp
								/>
							  }
							/>
						  </Feed.Content>
						</Feed.Event>
					  </Feed>
					</div>
				  );

				  case "AddBonus":
					return (
						<div
						  className={styles.notificationContainer}
						  style={{ backgroundColor: "#d9ead3" }}
						>
						  <Feed size={"small"}>
							<Feed.Event>
							  <Feed.Content>
								<div className={styles.feed}>
								  <Feed.Extra text className={styles.feedText}>
									<Icon color={"yellow"} name={"money"} className={styles.feedIcon} />
									{text}
								  </Feed.Extra>
								  {/* Uncomment if needed */}
								  {/* <Icon
									color={"black"}
									name={"trash"}
									className={styles.deleteIcon}
									onClick={(e) => {
									  onDelete(e, id);
									}}
								  /> */}
								</div>
								<Feed.Date
								  className={styles.notificationTimeStyle}
								  content={
									<ReactTimeAgo
									  timeStyle="time"
									  date={new Date(date).getTime()} // Ensure date is a valid timestamp
									/>
								  }
								/>
							  </Feed.Content>
							</Feed.Event>
						  </Feed>
						</div>
					  );

					  case "AdminDecline":
						return (
							<div
							  className={styles.notificationContainer}
							  style={{ backgroundColor: "#d9ead3" }}
							>
							  <Feed size={"small"}>
								<Feed.Event>
								  <Feed.Content>
									<div className={styles.feed}>
									  <Feed.Extra text className={styles.feedText}>
										<Icon color={"red"} name={"money"} className={styles.feedIcon} />
										{text}
									  </Feed.Extra>
									  {/* Uncomment if needed */}
									  {/* <Icon
										color={"black"}
										name={"trash"}
										className={styles.deleteIcon}
										onClick={(e) => {
										  onDelete(e, id);
										}}
									  /> */}
									</div>
									<Feed.Date
									  className={styles.notificationTimeStyle}
									  content={
										<ReactTimeAgo
										  timeStyle="time"
										  date={new Date(date).getTime()} // Ensure date is a valid timestamp
										/>
									  }
									/>
								  </Feed.Content>
								</Feed.Event>
							  </Feed>
							</div>
						  );

			case "ReduceBonus":
				return (
					<div
					  className={styles.notificationContainer}
					  style={{ backgroundColor: "#d9ead3" }}
					>
					  <Feed size={"small"}>
						<Feed.Event>
						  <Feed.Content>
							<div className={styles.feed}>
							  <Feed.Extra text className={styles.feedText}>
								<Icon color={"red"} name={"money"} className={styles.feedIcon} />
								{text}
							  </Feed.Extra>
							  {/* Uncomment if needed */}
							  {/* <Icon
								color={"black"}
								name={"trash"}
								className={styles.deleteIcon}
								onClick={(e) => {
								  onDelete(e, id);
								}}
							  /> */}
							</div>
							<Feed.Date
							  className={styles.notificationTimeStyle}
							  content={
								<ReactTimeAgo
								  timeStyle="time"
								  date={new Date(date).getTime()} // Ensure date is a valid timestamp
								/>
							  }
							/>
						  </Feed.Content>
						</Feed.Event>
					  </Feed>
					</div>
				  );


				  case "Logo":
					return (
						<div
						  className={styles.notificationContainer}
						  style={{ backgroundColor: "#d9ead3" }}
						>
						  <Feed size={"small"}>
							<Feed.Event>
							  <Feed.Content>
								<div className={styles.feed}>
								  <Feed.Extra text className={styles.feedText}>
									<Icon color={"blue"} name={"money"} className={styles.feedIcon} />
									{text}
								  </Feed.Extra>
								  {/* Uncomment if needed */}
								  {/* <Icon
									color={"black"}
									name={"trash"}
									className={styles.deleteIcon}
									onClick={(e) => {
									  onDelete(e, id);
									}}
								  /> */}
								</div>
								<Feed.Date
								  className={styles.notificationTimeStyle}
								  content={
									<ReactTimeAgo
									  timeStyle="time"
									  date={new Date(date).getTime()} // Ensure date is a valid timestamp
									/>
								  }
								/>
							  </Feed.Content>
							</Feed.Event>
						  </Feed>
						</div>
					  );

					  case "AdminAccept":
						return (
							<div
							  className={styles.notificationContainer}
							  style={{ backgroundColor: "#f7b2ad" }}
							>
							  <Feed size={"small"}>
								<Feed.Event>
								  <Feed.Content>
									<div className={styles.feed}>
									  <Feed.Extra text className={styles.feedText}>
										<Icon color={"pink"} name={"money"} className={styles.feedIcon} />
										{text}
									  </Feed.Extra>
									  {/* Uncomment if needed */}
									  {/* <Icon
										color={"black"}
										name={"trash"}
										className={styles.deleteIcon}
										onClick={(e) => {
										  onDelete(e, id);
										}}
									  /> */}
									</div>
									<Feed.Date
									  className={styles.notificationTimeStyle}
									  content={
										<ReactTimeAgo
										  timeStyle="time"
										  date={new Date(date).getTime()} // Ensure date is a valid timestamp
										/>
									  }
									/>
								  </Feed.Content>
								</Feed.Event>
							  </Feed>
							</div>
						  );

					  case "ReduceBalance":
						return (
							<div
							  className={styles.notificationContainer}
							  style={{ backgroundColor: "#f7b2ad" }}
							>
							  <Feed size={"small"}>
								<Feed.Event>
								  <Feed.Content>
									<div className={styles.feed}>
									  <Feed.Extra text className={styles.feedText}>
										<Icon color={"pink"} name={"money"} className={styles.feedIcon} />
										{text}
									  </Feed.Extra>
									  {/* Uncomment if needed */}
									  {/* <Icon
										color={"black"}
										name={"trash"}
										className={styles.deleteIcon}
										onClick={(e) => {
										  onDelete(e, id);
										}}
									  /> */}
									</div>
									<Feed.Date
									  className={styles.notificationTimeStyle}
									  content={
										<ReactTimeAgo
										  timeStyle="time"
										  date={new Date(date).getTime()} // Ensure date is a valid timestamp
										/>
									  }
									/>
								  </Feed.Content>
								</Feed.Event>
							  </Feed>
							</div>
						  );

			case "Alert":


				return (
					<div
					  className={styles.notificationContainer}
					  style={{ backgroundColor: "#d9ead3" }}
					>
					  <Feed size={"small"}>
						<Feed.Event>
						  <Feed.Content>
							<div className={styles.feed}>
							  <Feed.Extra text className={styles.feedText}>
							  <Icon color={"black"} name={"warning circle"} className={styles.feedIcon} />
								{text}
							  </Feed.Extra>
							  {/* Uncomment if needed */}
							  {/* <Icon
								color={"black"}
								name={"trash"}
								className={styles.deleteIcon}
								onClick={(e) => {
								  onDelete(e, id);
								}}
							  /> */}
							</div>
							<Feed.Date
							  className={styles.notificationTimeStyle}
							  content={
								<ReactTimeAgo
								  timeStyle="time"
								  date={new Date(date).getTime()} // Ensure date is a valid timestamp
								/>
							  }
							/>
						  </Feed.Content>
						</Feed.Event>
					  </Feed>
					</div>
				  );
			default:
				return null;
		}
	}
}

function mapStateToProps({ settings }) {
	return { settings };
}

export default connect(mapStateToProps)(Notifications);
